<template>
  <div
    class="card-template-manu-facture unselect"
    :style="user.partner_id ? 'display:flex' : 'display:grid'"
    @click="clickDetail">
    <div class="content">
      <div :class="`tag status-${card.status}`">{{ statusKor(card.status) }}</div>
      <div class="flex-align" style="gap: 12px">
        <img
          class="product-img unselect"
          :style="imgSize"
          :src="card.product.img"
          alt=""
          @click.stop="routerPush(`/template_detail?id=${card.product.id}`)" />
        <div>
          <div v-if="!user.partner_id" class="body5 sub2">{{ card.product.partner_nickname }}</div>
          <div class="body3-bold main">{{ card.product.name }}</div>
          <!-- <div class="body5 sub2">{{ optionKor(card.option) }}</div> -->
          <div class="body5 sub2">{{ card.simple_desc }}</div>
          <div v-if="!user.partner_id" class="body6 sub3">{{ datesFormat(card.created_time, 'date_3') }} 제작문의</div>
        </div>
      </div>
      <div v-if="user.partner_id" class="user-info-wrapper">
        <div class="flex-align body6 sub3" style="gap: 4px">
          <div class="main">{{ card.nickname }}</div>
          <div>·</div>
          <div>{{ datesFormat(card.created_time, 'date_3') }} 문의</div>
        </div>
        <div class="body3-bold main">
          {{ card.service_name }}
        </div>
        <div class="body5 sub3 ellipsis-2" style="height: fit-content !important">{{ card.simple_desc }}</div>
        <div class="partner-user-info-wrapper">
          <div class="flex-align" style="gap: 4px">
            <img src="/static/icon/u_money-bill-s.svg" alt="" style="width: 18px; height: 18px" />
            <div class="body4 sub3">예산</div>
            <div class="body4-medium main">{{ setBudget(card.budget) }}</div>
          </div>
          <div class="flex-align" style="gap: 4px">
            <img src="/static/icon/u_calender-s.svg" alt="" style="width: 18px; height: 18px" />
            <div class="body4 sub3">구축기간</div>
            <div class="body4-medium main">{{ card.period }}개월</div>
          </div>
          <div class="flex-align" style="gap: 4px">
            <img src="/static/icon/u_mobile-android-alt.svg" alt="" style="width: 18px; height: 18px" />
            <div class="body4 sub3">지원환경</div>
            <div class="body4-medium main">{{ envKor(card.env) }}</div>
          </div>
        </div>
      </div>
      <div v-if="!user.partner_id" class="req-link unselect">
        <span class="body4-medium main">{{ card.service_name }}</span>
        <div class="flex-align primary" @click.stop="openProductionReqModal">
          <span class="body4">제작요청서</span>
          <i class="material-icons" style="font-size: 16px">keyboard_arrow_right</i>
        </div>
      </div>
      <div class="flex-align" style="gap: 12px">
        <button v-if="!card.counsel_review && !user.partner_id" class="button" @click.stop="openReviewModal">
          상담후기 등록
        </button>
        <button v-else-if="user.partner_id" class="button" @click.stop="openProductionReqModal">제작 요청서</button>
        <button class="button is-main" @click.stop="clickDetail">
          <div class="flex-center" style="gap: 4px">
            <div class="body5-medium main">채팅</div>
            <div v-if="card.message_count > 0" class="notification-count">{{ card.message_count }}</div>
          </div>
        </button>
      </div>
    </div>
    <div v-if="!user.partner_id" class="info">
      <div class="body4-bold main">{{ card.service_name }}</div>
      <div class="body5 main ellipsis-2">{{ card.simple_desc }}</div>
      <div class="grid-info">
        <svg-icon icon="u_money-bill" color="#aeaeae" :width="18" :height="18" />
        <div class="body4 sub3">예산</div>
        <div class="body4-bold main">{{ setBudget(card.budget) }}</div>
        <svg-icon icon="u_calender" color="#aeaeae" :width="18" :height="18" />
        <div class="body4 sub3">구축기간</div>
        <div class="body4-bold main">{{ card.period }}개월</div>
        <svg-icon icon="u_mobile-android-alt" color="#aeaeae" :width="18" :height="18" />
        <div class="body4 sub3">지원환경</div>
        <div class="body4-bold main">{{ envKor(card.env) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from '@/components/component/SvgIcon.vue';
export default {
  name: 'CardTemplateManuFacture',
  components: {
    SvgIcon,
  },
  mixins: [],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    imgSize() {
      let size = this.user.partner_id ? 40 : 72;
      const [w, h] = this.imgRate(size);
      return {
        width: w + 'px',
        height: h + 'px',
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openReviewModal() {
      this.$emit('openReviewModal', this.card);
    },
    openProductionReqModal() {
      this.$emit('openProductionReqModal', this.card);
    },
    clickDetail() {
      let query = {
        id: this.card.id,
      };
      if (this.$route.path.includes('partner_center')) {
        query.partner = true;
      }
      this.$router.push({
        path: '/template_manufacture_detail',
        query: query,
      });
    },
    statusKor(status) {
      return {
        0: '상담전',
        1: '상담중',
        2: '상담완료',
        3: '계약확정',
      }[status];
    },
    optionKor(option) {
      return {
        0: '템플릿 그대로 제작',
        1: '템플릿을 바탕으로 수정 제작',
      }[option];
    },
    setBudget(budget) {
      return (budget * 10000).toLocaleString('ko-KR') + '원';
    },
    envKor(env) {
      return {
        0: 'Mobile Web 단독\n(PC로 접속시 Mobile Web 뷰 제공)',
        1: 'PC Web + Mobile Web',
        2: 'PC Web + Mobile Web + Mobile App',
        3: 'Mobile App',
      }[env];
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.card-template-manu-facture
  width 100%
  border-radius 12px
  background-color white
  display grid
  grid-template-columns 70% 30%

  .content
    width 100%
    padding 24px
    display flex
    flex-direction column
    gap 16px
    .tag
      width fit-content
      font-size 12px
      font-weight 700
      color $main
      padding 4px 8px
      border-radius 4px
      &.status-0
        background-color $gray2
      &.status-1
        background-color $primary
        color white
      &.status-2
        background-color $gray2

    .product-img
      border-radius 4px
      border 1px solid $gray2
      object-fit cover

    .button
      width 100%
      font-weight 500
      font-size 14px
    .notification-count
      width 18px
      height 18px
      display flex
      align-items center
      justify-content center
      border-radius 50%
      background-color $error
      color white
      font-size 10px
      font-weight 600

  .info
    border-left 1px solid $gray2
    width 100%
    height 100%
    padding 24px

  .ellipsis-2
    display -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    overflow hidden

  .grid-info
    margin-top 12px
    display grid
    grid-template-columns 18px 60px 1fr
    gap 12px 8px
    white-space pre-wrap
    svg
      margin-top 1px
  .req-link
    display none
    align-items center
    gap 8px
.user-info-wrapper
  display flex
  flex-direction column
  gap 8px
.partner-user-info-wrapper
  display flex
  align-items center
  gap 8px

@media (max-width: 1024px)
  .card-template-manu-facture
    grid-template-columns 1fr
    .info
      display none

    .req-link
      display flex
      border-top 1px solid $gray2
      padding-top 16px
    .button
      height 44px

@media (max-width: 425px)
  .partner-user-info-wrapper
    flex-direction column
    align-items flex-start
</style>
